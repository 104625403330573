import React from "react";
import { AiTwotonePhone } from "react-icons/ai";
import { IoLogoWhatsapp } from "react-icons/io";

const StickyIcons = () => {
  return (
    <div className="fixed right-7 md:right-16 bottom-36 md:bottom-36 space-y-3">
      <a href="tel:+919910137776" className="grid place-items-center rounded bg-white bg-opacity-90 shadow-lg hover:bg-[#A5FECB] hover:bg-opacity-100 p-5 transition duration-300 ease-in-out">
        <AiTwotonePhone size={20} color="black" />
      </a>
      <a href="https://api.whatsapp.com/send?phone=+919910137776" className="grid place-items-center rounded bg-white bg-opacity-90 shadow-lg hover:bg-[#A5FECB] hover:bg-opacity-100 p-5 transition duration-300 ease-in-out">
        <IoLogoWhatsapp size={20} color="black" />
      </a>
    </div>
  );
};

export default StickyIcons;
