import React from "react";
import { Link } from "react-router-dom";

import { AiFillCopy, AiFillTool } from "react-icons/ai";
import { FaHandPeace } from "react-icons/fa";
import { BsFillPersonCheckFill } from "react-icons/bs";

const Industries = () => {
  return (
    <main>
      {/* Industries Heading */}
      <div class="bg-[url(../src/props/bg/in-area-1-bg.jpg)] bg-cover bg-center h-48">
        <div className="bg-black bg-opacity-50 h-48">
          <div className="my-space h-48 grid place-items-center">
            <h2 className="font-bungee text-4xl text-white">Industries</h2>
          </div>
        </div>
      </div>

      {/* Industries we Cover */}
      <section className="my-space pt-24 pb-8">
        <h2 className="font-bungee text-3xl text-center mb-5 text-[#20BDFF]">
          Integrated ERP Solutions for Your Industry
        </h2>
        <p className="text-center">
          Our easy to use cloud and on-premise ERP softwares are full-featured
          and built to have a real-time view of your business anytime, anywhere.
        </p>

        <div className="container mx-auto px-5 py-16 w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7">
          <span className="bg-[url(../src/props/img/in-prop-1.jpg)] bg-cover bg-center my-service-card group">
            <div className="my-service-card-animation">
              <h3 className="w-fit p-2 rounded bg-black bg-opacity-60 animate-bounce group-hover:animate-none group-hover:translate-y-1">
                Printing
              </h3>
              <ul className="text-md md:text-sm text-left my-service-card-elements">
                <li>- Formula based estimation / Excel based estimation</li>
                <li>- Multiple Delivery address allocation</li>
                <li>- Estimation comparison with invoice</li>
                <li>- Actual vs done wastage analysis</li>
                <li>- Preventive maintenance report</li>
                <li>- Delivery Schedule Report</li>
                <li>- Stock Movement</li>
                <li>- Gate Pass</li>
              </ul>
            </div>
          </span>
          <span className="bg-[url(../src/props/img/in-prop-2.jpg)] bg-cover bg-center my-service-card group">
            <div className="my-service-card-animation">
              <h3 className="w-fit p-2 rounded bg-black bg-opacity-60 animate-bounce group-hover:animate-none group-hover:translate-y-1">
                Logistics
              </h3>
              <ul className="text-md md:text-sm text-left my-service-card-elements">
                <li>- Payment &amp; Receipt</li>
                <li>- Barcode Scanning</li>
                <li>- Stock Movement</li>
                <li>- GST Invoice</li>
                <li>- GST Reports</li>
                <li>- MIS Reports</li>
              </ul>
            </div>
          </span>
          <span className="bg-[url(../src/props/img/in-prop-3.jpg)] bg-cover bg-center my-service-card group">
            <div className="my-service-card-animation">
              <h3 className="w-fit p-2 rounded bg-black bg-opacity-60 animate-bounce group-hover:animate-none group-hover:translate-y-1">
                School
              </h3>
              <ul className="text-md md:text-sm text-left my-service-card-elements">
                <li>- Due list to Parent, Student, and class Teacher</li>
                <li>- Fuel refiling and average cost calculation</li>
                <li>- Barcode facility for book Issue / Receipt</li>
                <li>- Leave allocation and sanction details</li>
                <li>- Stock valuation with FIFO basis</li>
                <li>- Transport log book</li>
              </ul>
            </div>
          </span>
          <span className="bg-[url(../src/props/img/in-prop-4.jpg)] bg-cover bg-center my-service-card group">
            <div className="my-service-card-animation">
              <h3 className="w-fit p-2 rounded bg-black bg-opacity-60 animate-bounce group-hover:animate-none group-hover:translate-y-1">
                Finance
              </h3>
              <ul className="text-md md:text-sm text-left my-service-card-elements">
                <li>- Borrower Agreement, Co-Borrower, Gurantor details</li>
                <li>- Cheque bounce &amp; Cheque bouncing charges</li>
                <li>- Amount recovery &amp; age recovery calculation</li>
                <li>- Cheque daily Penality on late payment</li>
                <li>- Send pending EMI using Email &amp; SMS</li>
                <li>- Cash clearing / Cheque clearing</li>
                <li>- Late clearing reports</li>
              </ul>
            </div>
          </span>
          <span className="bg-[url(../src/props/img/in-prop-5.jpg)] bg-cover bg-center my-service-card group">
            <div className="my-service-card-animation">
              <h3 className="w-fit p-2 rounded bg-black bg-opacity-60 animate-bounce group-hover:animate-none group-hover:translate-y-1">
                Chit Fund
              </h3>
              <ul className="text-md md:text-sm text-left my-service-card-elements">
                <li>
                  - Dividend, Commision, Dividend earned, Company investment
                </li>
                <li>
                  - Data wise / Group wise prized Chit Payable / Advance details
                </li>
                <li>- Interest calculation on advance prized chits</li>
                <li>- List of Subscribers &amp; Guarantors</li>
              </ul>
            </div>
          </span>
          <span className="bg-[url(../src/props/img/in-prop-6.jpg)] bg-cover bg-center my-service-card group">
            <div className="my-service-card-animation">
              <h3 className="w-fit p-2 rounded bg-black bg-opacity-60 animate-bounce group-hover:animate-none group-hover:translate-y-1">
                GST &amp; Inventory
              </h3>
              <ul className="text-md md:text-sm text-left my-service-card-elements">
                <li>- Payment &amp; Receipt</li>
                <li>- Barcode Scanning</li>
                <li>- Stock Movement</li>
                <li>- GST Invoice</li>
                <li>- GST Reports</li>
                <li>- MIS Reports</li>
              </ul>
            </div>
          </span>

          <span className="bg-[url(../src/props/img/in-prop-7.jpg)] bg-cover bg-center my-service-card group">
            <div className="my-service-card-animation">
              <h3 className="w-fit p-2 rounded bg-black bg-opacity-60 animate-bounce group-hover:animate-none group-hover:translate-y-1">
                Institute
              </h3>
              <ul className="text-md md:text-sm text-left my-service-card-elements">
                <li>- Covers Comprehensive Management Modules</li>
                <li>- Fees Reminders using SMS and / or Emails</li>
                <li>- Keeps track of the Student Enquiries</li>
                <li>- Keep track of Pending payments</li>
              </ul>
            </div>
          </span>
          <span className="bg-[url(../src/props/img/in-prop-8.jpg)] bg-cover bg-center my-service-card group">
            <div className="my-service-card-animation">
              <h3 className="w-fit p-2 rounded bg-black bg-opacity-60 animate-bounce group-hover:animate-none group-hover:translate-y-1">
                Tender
              </h3>
              <ul className="text-md md:text-sm text-left my-service-card-elements">
                <li>
                  - A complete life cycle of Purchase Request, Purchase order,
                  goods, &amp; products received with payment
                </li>
                <li>
                  - Provides secrecy like traditional practice of sealing the
                  contract in a safe
                </li>
                <li>
                  - Innovative templates to collaborate with buyers &amp;
                  suppliers
                </li>
                <li>- Integrated fully automated e-tendering system</li>
                <li>- Saves time, money &amp; resources</li>
              </ul>
            </div>
          </span>
          <span className="bg-[url(../src/props/img/in-prop-9.jpg)] bg-cover bg-center my-service-card group">
            <div className="my-service-card-animation">
              <h3 className="w-fit p-2 rounded bg-black bg-opacity-60 animate-bounce group-hover:animate-none group-hover:translate-y-1">
                HR/Payroll
              </h3>
              <ul className="text-md md:text-sm text-left my-service-card-elements">
                <li>
                  - Standardize and automate local &amp; global HR processes to
                  increase efficiency
                </li>
                <li>
                  - Increase visibility and transparency into your HR processes
                </li>
                <li>
                  - Increase compliance with ever changing legal regulations
                </li>
                <li>- Manage an increasingly global, diverse workforce</li>
              </ul>
            </div>
          </span>
        </div>
      </section>

      {/* Why KATS iPro ERP */}
      <section className="bg-[url(../src/props/bg/in-area-2-bg.jpg)] bg-cover bg-center min-h-screen">
        <div className="bg-black bg-opacity-50 min-h-screen">
          <div className="my-space my-section-style py-20">
            <h2 className="font-bungee text-4xl text-center text-[#A5FECB]">
              Why KATS iPro?
            </h2>

            <p className="py-5 text-center text-white">
              Our Integrated Solutions for complete Accounting, Inventory
              Control for medium to large-scale organizations, Tax Reports, GST,
              Invoicing, Schemes &amp; much more.
            </p>

            <div className="w-full md:w-auto grid grid-cols-1 md:grid-cols-2 place-items-center gap-5 text-[#0f3443]">
              <span className="w-3/4 md:w-full p-10 md:p-5 bg-[#A5FECB] bg-opacity-80 hover:bg-opacity-100 rounded-lg flex flex-col justify-center items-center">
                <AiFillCopy size={48} />
                <h3>GST Compliant</h3>
              </span>
              <span className="w-3/4 md:w-full p-10 md:p-5 bg-[#A5FECB] bg-opacity-80 hover:bg-opacity-100 rounded-lg flex flex-col justify-center items-center">
                <AiFillTool size={48} />
                <h3>Fully Customizable</h3>
              </span>
              <span className="w-3/4 md:w-full p-10 md:p-5 bg-[#A5FECB] bg-opacity-80 hover:bg-opacity-100 rounded-lg flex flex-col justify-center items-center">
                <FaHandPeace size={48} />
                <h3>Easy Implementation</h3>
              </span>
              <span className="w-3/4 md:w-full p-10 md:p-5 bg-[#A5FECB] bg-opacity-80 hover:bg-opacity-100 rounded-lg flex flex-col justify-center items-center">
                <BsFillPersonCheckFill size={48} />
                <h3>Customer Driven Features</h3>
              </span>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Us */}
      <section class="bg-[url(../src/props/bg/in-area-3-bg.jpg)] bg-cover bg-center min-h-screen">
        <div className="my-space my-section-style">
          <h2 className="font-bungee text-[#5433FF] text-4xl text-center">
            Let's have a Chat
          </h2>
          <p className="py-5">
            We'll tell you how KATS iPro ERP Solution is the best fit for your
            Business
          </p>
          <Link to="/contact">
            <button className="my-btn bg-[#5433FF] text-white uppercase font-bold">
              Contact Us
            </button>
          </Link>
        </div>
      </section>
    </main>
  );
};

export default Industries;
