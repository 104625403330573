import React from "react";
import { Link } from "react-router-dom";

import abProp1 from "../props/img/ab-prop-1.jpg";
import abProp2 from "../props/img/ab-prop-2.jpg";
import abProp3 from "../props/img/ab-prop-3.jpg";

import abProp4 from "../props/img/developer.png";
import abProp5 from "../props/img/technologies.png";
import abProp6 from "../props/img/ip.png";
import abProp7 from "../props/img/methodologies.png";

const Company = () => {
  return (
    <main>
      {/* Company Heading */}
      <div class="bg-[url(../src/props/bg/ab-area-1-bg.jpg)] bg-cover bg-center h-48">
        <div className="bg-black bg-opacity-50 h-48">
          <div className="my-space h-48 grid place-items-center">
            <h2 className="font-bungee text-4xl text-white">Company</h2>
          </div>
        </div>
      </div>

      {/* About Us */}
      <section className="my-space min-h-screen p-12 flex justify-center flex-wrap items-center">
        <span className="md:w-1/2 py-5 md:p-10 text-center md:text-left">
          <h3 className="font-bungee text-2xl">About Us</h3>
          <h4 className="text-xl font-bold pt-2 pb-3">
            We are one stop solution to all your IT needs.
          </h4>
          <p className="text-lg lg:text-md">
            We are well-positioned to meet all your needs like an undisputed leader, trusted partner and virtuous
            vendor. From the latest ERP Software to Mobile/Desktop Apps Development, our team is ever ready to work with
            unmatched expertise.
          </p>
          <br />
        </span>

        <span className="md:w-1/2 grid place-items-center">
          <img
            src={abProp1}
            alt="Kats IT Solutions"
            className="md:w-4/5 rounded"
          />
        </span>
      </section>

      {/* Our Story */}
      <section className="bg-[#20BDFF] bg-opacity-90 my-space min-h-screen p-12 flex md:flex-row-reverse justify-center flex-wrap items-center">
        <span className="md:w-1/2 md:p-10 text-white text-center md:text-left">
          <h3 className="font-bungee text-2xl">Our Mission</h3>
          <h4 className="text-xl font-bold pt-2 pb-3">
            Always ready to help our client meet their business needs in IT.
          </h4>
          <p className="text-lg lg:text-md">
            Catering to all your IT needs in the most excellent ways, we embark
            upon our mission to be closer to your aspirations. Offering you the
            most effective and pocket-friendly solutions, we surge ahead,
            setting newer milestones in the path of progress.
          </p>
        </span>

        <span className="md:w-1/2 grid place-items-center">
          <img
            src={abProp2}
            alt="Kats IT Solutions"
            className="md:w-4/5 rounded"
          />
        </span>
      </section>

      {/* Our Vision */}
      <section className="my-space min-h-screen p-12 flex justify-center flex-wrap items-center">
        <span className="md:w-1/2 md:p-10 text-center md:text-left">
          <h3 className="font-bungee text-2xl">Our Vision</h3>
          <h4 className="text-xl font-bold pt-2 pb-3">
            We aim to meet the current rising needs of the IT innovation.
          </h4>
          <p className="text-lg lg:text-md">
            We envision ourselves as a trendsetter in the IT niche as far as all
            IT needs and services are concerned. The ultimate vision is to
            emerge as an enterprise exclusively known for excellence.
          </p>
        </span>

        <span className="md:w-1/2 grid place-items-center">
          <img
            src={abProp3}
            alt="Kats IT Solutions"
            className="md:w-4/5 rounded"
          />
        </span>
      </section>

      {/* Why work with Us? */}
      <section class="bg-[url(../src/props/bg/ab-area-2-bg.jpg)] bg-cover bg-center min-h-screen">
        <div className="bg-black bg-opacity-30 py-10 lg:py-3 text-white">
          <div className="my-space min-h-screen flex flex-col justify-center items-center">
            <div className="my-space p-4 grid place-items-center">
              <h3 className="font-bungee text-2xl">Why work with us?</h3>
              <h4 className="text-xl bold pt-2 pb-3 text-center">
                We Focus on giving our best work and building strong, long term
                relationship with our clients.
              </h4>
            </div>

            <div className="flex flex-wrap justify-center items-center">
              <span className="grid place-items-center w-72 text-center text-black m-2 p-5 bg-white hover:bg-opacity-100 bg-opacity-80 shadow-md rounded">
                <img
                  src={abProp4}
                  alt="Qualified Professionals"
                  className="w-16 pb-4"
                />
                <p>Access to Well-trained &amp; Qualified Professionals.</p>
              </span>

              <span className="grid place-items-center w-72 text-center text-black m-2 p-5 bg-white hover:bg-opacity-100 bg-opacity-80 shadow-md rounded">
                <img
                  src={abProp5}
                  alt="Diverse Technologies"
                  className="w-16 pb-4"
                />
                <p>Diverse Technology Skills, all under One Roof.</p>
              </span>

              <span className="grid place-items-center w-72 text-center text-black m-2 p-5 bg-white hover:bg-opacity-100 bg-opacity-80 shadow-md rounded">
                <img src={abProp6} alt="IP Protection" className="w-16 pb-4" />
                <p>Well-defined Intellectual Property Protection.</p>
              </span>

              <span className="grid place-items-center w-72 text-center text-black m-2 p-5 bg-white hover:bg-opacity-100 bg-opacity-80 shadow-md rounded">
                <img
                  src={abProp7}
                  alt="Qualified Professionals"
                  className="w-16 pb-4"
                />
                <p>Flexible Methodologies of Development and Service.</p>
              </span>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Us */}
      <section class="bg-[url(../src/props/bg/ab-area-3-bg.jpg)] bg-cover bg-center min-h-screen">
        <div className="my-space my-section-style">
          <h2 className="font-bungee text-4xl text-center">
            Ready to boost your Business to <br /> the Next Level?
          </h2>
          <p className="py-5">
            Whether you have a query or need help with your tech requirements,
            just ping us.
          </p>
          <Link to="/contact">
            <button className="my-btn bg-[#5433FF] text-white uppercase font-bold">
              Contact Us
            </button>
          </Link>
        </div>
      </section>
    </main>
  );
};

export default Company;
