import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { ContactForm } from "../components";

import area2prop1 from "../props/img/experience.png";
import area2prop2 from "../props/img/buildings.png";
import area2prop3 from "../props/img/support.png";

import area4prop1 from "../props/clients/emami.png";
import area4prop2 from "../props/clients/pidilite.png";
import area4prop3 from "../props/clients/dabur.png";
import area4prop4 from "../props/clients/johnson.png";

const Homepage = () => {
  const [messageStatusSent, setMessageStatusSent] = useState(false);

  const callback = useCallback((messageStatusSent) => {
    setMessageStatusSent(messageStatusSent);
  }, []);

  return (
    <main className="text-center">
      {/* Area 1 */}
      <section className="bg-[url(../src/props/bg/hp-area-1-bg.png)] bg-cover bg-center min-h-screen">
        <div className="bg-black bg-opacity-50 min-h-screen">
          <div className="my-space my-section-style text-white">
            <h2 className="font-bungee text-4xl text-center">
              Ready to boost your Business to the Next Level?
            </h2>
            <p className="py-5">
              We provide best in class tech solutions for all your needs and
              wants.
            </p>
            <Link to="/services">
              <button className="my-btn bg-[#5433FF] uppercase font-bold">
                Get Started
              </button>
            </Link>
          </div>
        </div>
      </section>

      {/* Area 2 */}
      <section className="bg-[url(../src/props/bg/hp-area-2-bg.png)] bg-cover bg-center min-min-h-screen">
        <div className="my-space my-section-style py-20">
          <h2 className="font-bungee text-4xl text-center text-[#20BDFF]">
            Digital First,
            <br />
            Knowledge Applied,
            <br />
            Thoroughly Tested
          </h2>

          <p className="py-5">
            With Quality in our genes, security in our heart, art in our eyes
            and safety in our mind we handle every project.
          </p>

          <div className="mb-10 text-white flex flex-wrap gap-8 lg:gap-12 justify-center items-center">
            <span className="grid place-items-center rounded py-2 px-7 bg-[#20BDFF] bg-opacity-70 hover:bg-opacity-100">
              <img src={area2prop1} alt="15+ years of Experience" />
              <h3>15+ years of Experience</h3>
            </span>
            <span className="grid place-items-center rounded py-2 px-7 bg-[#20BDFF] bg-opacity-70 hover:bg-opacity-100">
              <img src={area2prop2} alt="8+ Cities running ERPs" />
              <h3>8+ Cities running ERPs</h3>
            </span>
            <span className="grid place-items-center rounded py-2 px-7 bg-[#20BDFF] bg-opacity-70 hover:bg-opacity-100">
              <img src={area2prop3} alt="24/7 Dedicated Team" />
              <h3>24/7 Dedicated Team</h3>
            </span>
          </div>

          <Link to="/company">
            <button className="my-btn bg-[#5433FF] uppercase font-bold text-white">
              More About Us
            </button>
          </Link>
        </div>
      </section>

      {/* Area 3 */}
      <section className="bg-[url(../src/props/bg/hp-area-3-bg.jpg)] bg-cover bg-center min-h-screen">
        <div className="bg-black bg-opacity-50 min-h-screen">
          <div className="my-space my-section-style text-white">
            <h2 className="font-bungee text-4xl text-center text-[#A5FECB]">
              Jack of All. Master of All.
            </h2>

            <div className="md:w-1/2 my-space text-2xl">
              <div className="w-full flex justify-between p-2 space-y-12 md:space-y-0">
                <span>Software Development</span>
                <span>Web Development</span>
              </div>
              <div className="w-full flex justify-between p-2 space-y-12 md:space-y-0">
                <span>App Development</span>
                <span>ERP Solutions</span>
              </div>
            </div>

            <p className="py-5 md:w-1/2">
              Our well trained professionals with diverse tech skills, apply
              their knowledge to create intelligent products with speed, passion
              and team work.
            </p>
            <Link to="/services">
              <button className="my-btn bg-[#5433FF] uppercase font-bold">
                Our Services
              </button>
            </Link>
          </div>
        </div>
      </section>

      {/* Area 4 */}
      <section className="bg-[url(../src/props/bg/hp-area-4-bg.png)] bg-cover bg-center min-h-screen">
        <div className="my-space my-section-style">
          <h2 className="font-bungee text-4xl text-center text-[#5433FF]">
            Quality of Work,
            <br />
            Timely Delivery,
            <br />
            Years of trust.
          </h2>
          <p className="py-5">
            Our work methodology, clear goals and focus on Client satisfaction
            has earned us trust with top Industry leaders.{" "}
          </p>
          <div className="w-2/3 grid grid-cols-4 gap-20 place-content-center">
            <img src={area4prop1} alt="Emami" />
            <img src={area4prop2} alt="Pidilite" />
            <img src={area4prop3} alt="Dabur" />
            <img src={area4prop4} alt="Johnson" />
          </div>
        </div>
      </section>

      {/* Area 5 */}
      <section className="bg-[url(../src/props/bg/hp-area-5-bg.jpg)] bg-cover bg-center min-h-screen">
        <div className="bg-black bg-opacity-70 min-h-screen">
          <div className="my-space my-section-style text-white">
            <h2 className="font-bungee text-4xl text-center">
              You focus on your business,
              <br />
              we’ll handle your ERP.
            </h2>
            <p className="py-5">
              Be it Printing, Logistics, School or Finance, our ERP Solutions
              are best in class with security &amp; integrity in mind.
              <br />
              Need GST, Inventory, HR/Payroll? We’ve got your cover.
            </p>
            <Link to="/industries">
              <button className="my-btn bg-white text-black uppercase font-bold">
                Discover Industries
              </button>
            </Link>
          </div>
        </div>
      </section>

      {/* Area 6 */}
      <section className="bg-[url(../src/props/bg/hp-area-6-bg.jpg)] bg-cover bg-center min-h-screen">
        {!messageStatusSent ? (
          <div className="my-space my-section-style pt-20 pb-10">
            <h2 className="font-bungee text-4xl text-center text-[#5433FF]">
              So, Are you ready to grow?
            </h2>
            <p className="py-5">
              Just fill out the form and we’ll connect with you in no time.
            </p>

            <ContactForm parentCallback={callback} />
          </div>
        ) : (
          <div className="my-space my-section-style">
            <h2 className="font-bungee text-xl text-center text-[#5433FF]">
              Message Sent Successfully!
            </h2>
            <p className="py-5">
              You are on the right track. Just relax, we'll connect with you
              soon.
            </p>
          </div>
        )}
      </section>
    </main>
  );
};

export default Homepage;
