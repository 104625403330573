import React, { useState, useCallback } from "react";
import { ContactForm } from "../components";

import { FaTwitter, FaLinkedinIn, FaFacebookF } from "react-icons/fa";

import { RiInstagramFill } from "react-icons/ri";

import cnProp1 from "../props/img/cn-prop-1.png";

const Contact = () => {
  const [messageStatusSent, setMessageStatusSent] = useState(false);

  const callback = useCallback((messageStatusSent) => {
    setMessageStatusSent(messageStatusSent);
  }, []);

  return (
    <main>
      {/* Services Heading */}
      <div className="bg-[url(../src/props/bg/cn-area-1-bg.jpg)] bg-cover bg-center h-48">
        <div className="bg-black bg-opacity-50 h-48">
          <div className="my-space h-48 grid place-items-center">
            <h2 className="font-bungee text-4xl text-white">Contact</h2>
          </div>
        </div>
      </div>

      {/* Socials */}
      <div className="px-3 py-5 flex flex-wrap items-center justify-center gap-3">
        <a
          href="https://twitter.com/katsInfotech_"
          target="_blank"
          rel="noopener noreferrer"
          className="grid place-items-center text-white text-sm rounded py-2 px-7 w-44 h-20 aspect-square bg-[#20BDFF] bg-opacity-70 hover:bg-opacity-100"
        >
          <FaTwitter size={24} color="white" />
          <p>@katsInfotech_</p>
        </a>
        <a
          href="https://www.linkedin.com/company/kats-infotech"
          target="_blank"
          rel="noopener noreferrer"
          className="grid place-items-center text-white text-sm rounded py-2 px-7 w-44 h-20 aspect-square bg-[#20BDFF] bg-opacity-70 hover:bg-opacity-100"
        >
          <FaLinkedinIn size={24} color="white" />
          <p>@kats-infotech</p>
        </a>
        <a
          href="https://www.instagram.com/katsinfotech/"
          target="_blank"
          rel="noopener noreferrer"
          className="grid place-items-center text-white text-sm rounded py-2 px-7 w-44 h-20 aspect-square bg-[#20BDFF] bg-opacity-70 hover:bg-opacity-100"
        >
          <RiInstagramFill size={24} color="white" />
          <p>@katsinfotech</p>
        </a>
        <a
          href="https://www.facebook.com/katsinfotechpvtltd/"
          target="_blank"
          rel="noopener noreferrer"
          className="grid place-items-center text-white text-sm rounded py-2 px-7 w-44 h-20 aspect-square bg-[#20BDFF] bg-opacity-70 hover:bg-opacity-100"
        >
          <FaFacebookF size={24} color="white" />
          <p>@katsinfotechpvtltd</p>
        </a>
      </div>

      {/* Contact Form */}
      <section className="bg-[url(../src/props/bg/cn-area-2-bg.jpg)] bg-cover bg-center min-h-screen">
        {!messageStatusSent ? (
          <div className="my-space my-section-style pt-20 pb-10">
            <h2 className="font-bungee text-4xl text-center text-white">
              So, Are you ready to grow?
            </h2>
            <p className="py-5 text-center text-white">
              Just fill out the form and we'll connect with you in no time.
            </p>

            <ContactForm parentCallback={callback} />
          </div>
        ) : (
          <div className="my-space my-section-style">
            <h2 className="font-bungee text-xl text-center text-[#5433FF]">
              Message Sent Successfully!
            </h2>
            <p className="py-5 text-white">
              You are on the right track. Just relax, we'll connect with you
              soon.
            </p>
          </div>
        )}
      </section>

      {/* Call Us */}
      <section className="my-space h-screen p-12 flex justify-center flex-wrap items-center">
        <span className="md:w-1/2 grid place-items-center">
          <img src={cnProp1} alt="Call Kats" className="md:w-4/5 rounded" />
        </span>
        <span className="md:w-1/2 md:p-10">
          <h3 className="font-bungee text-2xl">Wanna Talk?</h3>
          <h4 className="text-xl bold pt-2 pb-3">
            We'll be happy to help you. Don't be shy, just call us.
          </h4>
          <a href="tel:+919910137776">
            <button className="my-btn bg-[#5433FF] text-white uppercase font-bold">
              Dial Now
            </button>
          </a>
        </span>
      </section>
    </main>
  );
};

export default Contact;
