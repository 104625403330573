import React from "react";

import {
  FaTwitter,
  FaLinkedinIn,
  FaFacebookF,
} from "react-icons/fa";

import { RiInstagramFill } from "react-icons/ri";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className="my-theme-color-rev">
      <div className="my-space flex justify-between items-center text-white text-sm font-bold">
        <div>&copy; 2022 Kats Infotech Pvt. Ltd.</div>

        <div className="hidden md:flex md:items-center md:justify-center gap-3">
          <a
            href="https://twitter.com/katsInfotech_"
            target="_blank"
            rel="noopener noreferrer"
            className="rounded bg-[#5433FF] bg-opacity-80 hover:bg-black p-2 transition duration-300 ease-in-out"
          >
            <FaTwitter size={16} color="white" />
          </a>
          <a
            href="https://www.linkedin.com/company/kats-infotech"
            target="_blank"
            rel="noopener noreferrer"
            className="rounded bg-[#5433FF] bg-opacity-80 hover:bg-black p-2 transition duration-300 ease-in-out"
          >
            <FaLinkedinIn size={16} color="white" />
          </a>
          <a
            href="https://www.instagram.com/katsinfotech/"
            target="_blank"
            rel="noopener noreferrer"
            className="rounded bg-[#5433FF] bg-opacity-80 hover:bg-black p-2 transition duration-300 ease-in-out"
          >
            <RiInstagramFill size={16} color="white" />
          </a>
          <a
            href="https://www.facebook.com/katsinfotechpvtltd/"
            target="_blank"
            rel="noopener noreferrer"
            className="rounded bg-[#5433FF] bg-opacity-80 hover:bg-black p-2 transition duration-300 ease-in-out"
          >
            <FaFacebookF size={16} color="white" />
          </a>
        </div>

        <button
          onClick={scrollToTop}
          className="my-scroll-to-top my-btn align-middle w-1/10 h-10"
        >
          <div className="font-bold cursor-pointer flex">
            <span className="hidden md:flex">Scroll to Top&nbsp;</span>
            <span>▲</span>
          </div>
        </button>
      </div>
    </footer>
  );
};

export default Footer;
