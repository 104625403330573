import React, { useState } from "react";
import emailjs from "@emailjs/browser";

const ContactForm = ({ parentCallback }) => {
  const initialValues = {
    user_name: "",
    user_email: "",
    user_phone: "",
    user_company: "",
    message: "",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({
    user_name: "",
    user_email: "",
    user_phone: "",
    message: "",
  });

  const [hasError, setHasError] = useState(true);
  const [messageStatusSent, setMessageStatusSent] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validate = (values) => {
    const errors = {};
    let numOfErrors = 0;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.user_name) {
      errors.user_name = "Name is Required!";
      numOfErrors++;
    }

    if (!values.user_email) {
      errors.user_email = "Email is Required!";
    } else if (!regex.test(values.user_email)) {
      errors.user_email = "Enter a valid email format!";
      numOfErrors++;
    }

    if (!values.user_phone) {
      errors.user_phone = "Phone is Required!";
      numOfErrors++;
    }

    if (!values.message) {
      errors.message = "Message is Required!";
      numOfErrors++;
    }

    if (numOfErrors === 0) {
      setHasError(false);
    }
    return errors;
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));

    if (hasError === false) {
      setMessageStatusSent(!messageStatusSent);
      parentCallback(!messageStatusSent);

      emailjs
        .send(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          formValues,
          process.env.REACT_APP_PUBLIC_KEY
        )
        .then(
          () => {
            console.log("Message Sent!");
          },
          (err) => {
            alert(JSON.stringify(err));
          }
        );
    } else {
      console.log(formErrors);
    }
  };

  return (
    <form className="lg:w-2/3 mb-5 my-space text-center">
      <div className="grid grid-cols-2 gap-5 py-2">
        <span className="bg-[#5433FF] bg-opacity-80 rounded p-2">
          {hasError && formErrors.user_name ? (
            <p className="text-sm text-red-300 text-left pl-1 pb-2">
              {formErrors.user_name}
            </p>
          ) : (
            <p className="text-sm text-white text-left pl-1 pb-2">Name</p>
          )}
          <input
            type="text"
            name="user_name"
            placeholder="*Enter your Full Name"
            required
            onChange={handleChange}
            className="w-full my-btn outline-0 bg-[#E5E5E5] bg-opacity-65"
          />
        </span>
        <span className="bg-[#5433FF] bg-opacity-80 rounded p-2">
          {hasError && formErrors.user_email ? (
            <p className="text-sm text-red-300 text-left pl-1 pb-2">
              {formErrors.user_email}
            </p>
          ) : (
            <p className="text-sm text-white text-left pl-1 pb-2">Email ID</p>
          )}
          <input
            type="text"
            name="user_email"
            placeholder="*Enter your Email ID"
            required
            onChange={handleChange}
            className="w-full my-btn outline-0 bg-[#E5E5E5] bg-opacity-65"
          />
        </span>
      </div>

      <div className="grid grid-cols-2 gap-5 py-2">
        <span className="bg-[#5433FF] bg-opacity-80 rounded p-2">
          {hasError && formErrors.user_phone ? (
            <p className="text-sm text-red-300 text-left pl-1 pb-2">
              {formErrors.user_phone}
            </p>
          ) : (
            <p className="text-sm text-white text-left pl-1 pb-2">
              Contact No.
            </p>
          )}
          <input
            type="text"
            name="user_phone"
            placeholder="*Enter your Contact No."
            required
            onChange={handleChange}
            className="w-full my-btn outline-0 bg-[#E5E5E5] bg-opacity-65"
          />
        </span>
        <span className="bg-[#5433FF] bg-opacity-80 rounded p-2">
          <p className="text-sm text-white text-left pl-1 pb-2">Company Name</p>
          <input
            type="text"
            name="user_company"
            placeholder="Enter your Company Name"
            onChange={handleChange}
            className="w-full my-btn outline-0 bg-[#E5E5E5] bg-opacity-65"
          />
        </span>
      </div>

      <div className="bg-[#5433FF] bg-opacity-80 rounded p-2 mt-2 grid grid-cols-1 gap-5">
        {hasError && formErrors.message ? (
          <p className="text-sm text-red-300 text-left pl-1">
            {formErrors.message}
          </p>
        ) : (
          <p className="text-sm text-white text-left pl-1">Message</p>
        )}
        <textarea
          type="text"
          name="message"
          placeholder="*Tell us, how can we help you?"
          required
          onChange={handleChange}
          className="h-40 my-btn outline-0 bg-[#E5E5E5] bg-opacity-65"
        />
      </div>

      <div>
        <input
          type="submit"
          value="Submit"
          className="my-btn mt-5 bg-[#5433FF] text-white font-bold uppercase cursor-pointer"
          onClick={sendEmail}
        />
      </div>
    </form>
  );
};

export default ContactForm;
