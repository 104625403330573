import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { Navbar, Homepage, Company, Industries, Services, Contact, Footer, StickyIcons, ScrollToTop } from './components';

const App = () => {
  return (
    <div className='bg-slate-100 dark:bg-slate-900 font-rubik select-none'>
      <Navbar />
      <ScrollToTop />
      <Routes className=''>
        <Route path="/" element={<Homepage />} />
        <Route path="/company" element={<Company />} />
        <Route path="/industries" element={<Industries />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <StickyIcons />
      <Footer />
    </div>
  )
}

export default App