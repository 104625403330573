import React from "react";
import { Link } from "react-router-dom";

import { ImHtmlFive, ImCss3 } from "react-icons/im";
import { IoMdPrint } from "react-icons/io";
import { IoSchool } from "react-icons/io5";
import { FaTruck, FaSchool, FaMoneyBill, FaFileContract } from "react-icons/fa";
import { RiFundsBoxFill } from "react-icons/ri";
import { MdInventory, MdPayments } from "react-icons/md";

import {
  SiJavascript,
  SiJava,
  SiCsharp,
  SiPostgresql,
  SiMysql,
  SiMongodb,
  SiVisualstudiocode,
  SiXamarin,
  SiAmazonaws,
  SiPython,
  SiOracle,
} from "react-icons/si";
import { DiDotnet, DiAndroid, DiJqueryLogo } from "react-icons/di";
import { FaNodeJs, FaReact, FaLinux } from "react-icons/fa";
import { IoLogoAppleAppstore } from "react-icons/io5";

const Services = () => {
  return (
    <main>
      {/* Services Heading */}
      <div className="bg-[url(../src/props/bg/sr-area-1-bg.jpg)] bg-cover bg-center h-48">
        <div className="bg-black bg-opacity-50 h-48">
          <div className="my-space h-48 grid place-items-center">
            <h2 className="font-bungee text-4xl text-white">Services</h2>
          </div>
        </div>
      </div>

      {/* What we Offer */}
      <section className="my-space pt-24 pb-8">
        <h2 className="font-bungee text-3xl mb-5 text-[#20BDFF] text-center">
          What we Offer?
        </h2>
        <p className="text-center">
          We design long-term strategies to help you overcome your challenges
          and get you where you want to be efficiently. We are here not just to
          check boxes based on requirements, but to solve problems.
        </p>

        <div className="container mx-auto px-5 py-16 w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7">
          <span className="bg-[url(../src/props/img/sr-prop-1.jpg)] bg-cover bg-center my-service-card group">
            <div className="my-service-card-animation text-center">
              <h3 className="w-full p-2 rounded bg-black bg-opacity-60 animate-bounce group-hover:animate-none group-hover:translate-y-1">
                Software Engineering
              </h3>
              <p className="text-md md:text-sm my-service-card-elements">
                We apply professional knowledge in software engineering to
                create out of the box solutions into a full fledged application
                that totally suits your project's requirements.
              </p>
            </div>
          </span>
          <span className="bg-[url(../src/props/img/sr-prop-2.jpg)] bg-cover bg-center my-service-card group">
            <div className="my-service-card-animation text-center">
              <h3 className="w-full p-2 rounded bg-black bg-opacity-60 animate-bounce group-hover:animate-none group-hover:translate-y-1">
                ERP Solutions
              </h3>
              <p className="text-md md:text-sm my-service-card-elements">
                Our best in class ERP Solutions leverage modern technologies to
                help you optimize routine business processes and manage various
                departments in your business.
              </p>
            </div>
          </span>
          <span className="bg-[url(../src/props/img/sr-prop-3.jpg)] bg-cover bg-center my-service-card group">
            <div className="my-service-card-animation text-center">
              <h3 className="w-full p-2 rounded bg-black bg-opacity-60 animate-bounce group-hover:animate-none group-hover:translate-y-1">
                Web Development
              </h3>
              <p className="text-md md:text-sm my-service-card-elements">
                Using latest technologies and proven strategies, we provide
                user-engaging business websites &amp; dynamic web applications
                to help you stand out in the competitive Online market.
              </p>
            </div>
          </span>
          <span className="bg-[url(../src/props/img/sr-prop-4.jpg)] bg-cover bg-center my-service-card group">
            <div className="my-service-card-animation text-center">
              <h3 className="w-full p-2 rounded bg-black bg-opacity-60 animate-bounce group-hover:animate-none group-hover:translate-y-1">
                Mobile Development
              </h3>
              <p className="text-md md:text-sm my-service-card-elements">
                The world is shifting towards on the go services on their
                smartphone. We cater to this demand by using innovative
                technologies to build Native and Hybrid Mobile Applications.
              </p>
            </div>
          </span>
          <span className="bg-[url(../src/props/img/sr-prop-5.jpg)] bg-cover bg-center my-service-card group">
            <div className="my-service-card-animation text-center">
              <h3 className="w-full p-2 rounded bg-black bg-opacity-60 animate-bounce group-hover:animate-none group-hover:translate-y-1">
                Desktop Development
              </h3>
              <p className="text-md md:text-sm my-service-card-elements">
                We combine our rich technical expertise, years of experience,
                and deep understanding of the low-level desktop OS architecture
                to develop great desktop applications to solve even the most
                complex challenges.
              </p>
            </div>
          </span>
          <span className="bg-[url(../src/props/img/sr-prop-6.jpg)] bg-cover bg-center my-service-card group">
            <div className="my-service-card-animation text-center">
              <h3 className="w-full p-2 rounded bg-black bg-opacity-60 animate-bounce group-hover:animate-none group-hover:translate-y-1">
                IT Consulting
              </h3>
              <p className="text-md md:text-sm my-service-card-elements">
                We help you develop and execute a clear &amp; strategic IT
                roadmap which aligns with your company values, business
                processes and information systems capabilities to solve all your
                IT needs and wants.
              </p>
            </div>
          </span>
        </div>
      </section>

      {/* Tech Stacks */}
      <section className="bg-[url(../src/props/bg/sr-area-2-bg.jpg)] bg-cover bg-center min-h-screen">
        <div className="my-space my-section-style py-16">
          <h2 className="font-bungee text-4xl text-center text-[#A5FECB]">
            We Stay Updated.
            <br /> We Stay Relevant.
          </h2>
          <p className="py-5 text-center text-white">
            Developing industry standard softwares with competency in a wide
            range of Tech Stacks
          </p>

          <div className="m-10 text-white grid grid-cols-4 md:grid-cols-5 gap-10 gap-x-20 place-items-center">
            <ImHtmlFive size={60} color="orange" />
            <ImCss3 size={60} color="deepskyblue" />
            <SiJavascript size={60} color="gold" className="bg-black" />
            <FaNodeJs size={60} color="green" />
            <SiJava size={60} color="red" />
            <SiCsharp size={60} color="purple" />
            <DiDotnet size={60} color="skyblue" />
            <SiPostgresql size={60} color="white" />
            <SiMysql size={60} color="darkorange" />
            <SiMongodb size={60} color="green" />
            <SiVisualstudiocode size={60} color="royalblue" />
            <FaReact size={60} color="darkturquoise" />
            <DiAndroid size={60} color="limegreen" />
            <IoLogoAppleAppstore size={60} color="deepskyblue" />
            <DiJqueryLogo size={60} color="dimgray" />
            <SiXamarin size={60} color="dodgerblue" />
            <FaLinux size={60} color="white" />
            <SiAmazonaws size={60} color="orange" />
            <SiPython size={60} color="steelblue" />
            <SiOracle size={60} color="red" />
          </div>
        </div>
      </section>

      {/* Our Signature ERP Solutions */}
      <section className="text-gray-600 body-font">
        <div className="my-space">
          <div className="py-16 text-center">
            <h2 className="pb-5 font-bungee text-4xl text-center text-[#5433FF]">
              Our Signature ERP Solutions
            </h2>
            <p>
              KATSiPro® ERP is Integrated Solution for complete Accounting and
              Inventory Control for medium to large-scale organizations, which
              covers Financial Accounting, Tax Reports including VAT, Invoicing,
              Schemes and MIS.
            </p>
          </div>

          <div className="flex flex-wrap justify-center mb-16 gap-10">
            <div className="my-service-erp">
              <IoMdPrint
                color="white"
                className="absolute right-5 -top-7 w-16 h-16 p-3 bg-[#5433FF] rounded-md"
              />
              <h2 className="text-xl font-bold my-2">Printing ERP</h2>
              <ul className="text-md md:text-sm text-left">
                <li>• Keep track of Job work details (Customer / Size wise)</li>
                <li>• Machine Maintenance &amp; Machine wise reports</li>
                <li>• Reduce the paper work with an oraganization</li>
                <li>• MIS Stock (Like Ink, Plates etc.)</li>
                <li>• Fully Customizable</li>
              </ul>
            </div>

            <div className="my-service-erp">
              <FaTruck
                color="white"
                className="absolute right-5 -top-7 w-16 h-16 p-3 bg-[#5433FF] rounded-md"
              />
              <h2 className="text-xl font-bold my-2">Logistics ERP</h2>
              <ul className="text-md md:text-sm text-left">
                <li>• Covers activities related to the physical flow of materials</li>
                <li>• Streamlined operations enable process improvements</li>
                <li>• ERP integration to improve logistics efficiency</li>
                <li>• Unified solutions to accelerate logistic process</li>
                <li>• Workflow driven management system</li>
              </ul>
            </div>

            <div className="my-service-erp">
              <FaSchool
                color="white"
                className="absolute right-5 -top-7 w-16 h-16 p-3 bg-[#5433FF] rounded-md"
              />
              <h2 className="text-xl font-bold my-2">School ERP</h2>
              <ul className="text-md md:text-sm text-left">
                <li>• Maintain student fees, tracks payments, &amp; balance info</li>
                <li>• Automate school libraries efficiently and effectively</li>
                <li>• GPS powered School Bus tracking System</li>
                <li>• Integrated with Comprehensive School Accounting</li>
                <li>• Power to Schedule teacher, libraries, Class Rooms</li>
              </ul>
            </div>

            <div className="my-service-erp">
              <FaMoneyBill
                color="white"
                className="absolute right-5 -top-7 w-16 h-16 p-3 bg-[#5433FF] rounded-md"
              />
              <h2 className="text-xl font-bold my-2">Finance ERP</h2>
              <ul className="text-md md:text-sm text-left">
                <li>• Finance Amount Recovery &amp; percentage Wise Recovery Calculation</li>
                <li>• HP Leadger, HP List, Finance List, HP Income List etc.</li>
                <li>• ERP integration to improve Financing efficiency</li>
                <li>• NOC &amp; Form-35 Printed and Pre-Printed</li>
                <li>• Area Wise Dealer Wise Outstanding</li>
              </ul>
            </div>

            <div className="my-service-erp">
              <RiFundsBoxFill
                color="white"
                className="absolute right-5 -top-7 w-16 h-16 p-3 bg-[#5433FF] rounded-md"
              />
              <h2 className="text-xl font-bold my-2">Chit Fund ERP</h2>
              <ul className="text-md md:text-sm text-left">
                <li>• Easy to Use</li>
                <li>• Fully Personalized for you</li>
                <li>• Desktop and Mobile App Compatible</li>
                <li>• Manage all branches with Reports, Billing &amp; Accounts</li>
                <li>• Fully Automated account calculations, Receipt &amp; Report</li>
              </ul>
            </div>

            <div className="my-service-erp">
              <IoSchool
                color="white"
                className="absolute right-5 -top-7 w-16 h-16 p-3 bg-[#5433FF] rounded-md"
              />
              <h2 className="text-xl font-bold my-2">Institute ERP</h2>
              <ul className="text-md md:text-sm text-left">
                <li>• RFID Attendance System</li>
                <li>• Desktop &amp; Mobile App compatible</li>
                <li>• Payroll Management for whole staff</li>
                <li>• Performance and Attendance Reporting</li>
                <li>• Human Resource (HR) Management System</li>
              </ul>
            </div>

            <div className="my-service-erp">
              <MdInventory
                color="white"
                className="absolute right-5 -top-7 w-16 h-16 p-3 bg-[#5433FF] rounded-md"
              />
              <h2 className="text-xl font-bold my-2">GST &amp; Inventory ERP</h2>
              <ul className="text-md md:text-sm text-left">
                <li>• Displays ageing analysis reports for debtors</li>
                <li>• Includes Sales Analysis &amp; Purchase Analysis</li>
                <li>• Reduce the paper work with an oraganization</li>
                <li>• Price list on the basis of item groups</li>
                <li>• Covers almost all Inventory Reports</li>
              </ul>
            </div>

            <div className="my-service-erp">
              <FaFileContract
                color="white"
                className="absolute right-5 -top-7 w-16 h-16 p-3 bg-[#5433FF] rounded-md"
              />
              <h2 className="text-xl font-bold my-2">Tender ERP</h2>
              <ul className="text-md md:text-sm text-left">
                <li>• Manage the process of administering &amp; receiving</li>
                <li>• Facilate the information management</li>
                <li>• Get facilities to Customized Reports</li>
                <li>• Handle Tender Content with Ease</li>
                <li>• Easy to use and 100% secure</li>
              </ul>
            </div>
            <div className="my-service-erp">
              <MdPayments
                color="white"
                className="absolute right-5 -top-7 w-16 h-16 p-3 bg-[#5433FF] rounded-md"
              />
              <h2 className="text-xl font-bold my-2">HR/Payroll ERP ERP</h2>
              <ul className="text-md md:text-sm text-left">
                <li>• Connectivity to multiple locations through WAN, Web, Wireless</li>
                <li>• Bio metric / Smart Card integration for Attendance &amp; Security</li>
                <li>• Integration with Third party applications</li>
                <li>• Email Tracking &amp; SMS alert system</li>
                <li>• Workflow driven management system</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Us */}
      <section class="bg-[url(../src/props/bg/sr-area-3-bg.jpg)] bg-cover bg-center min-h-screen">
        <div className="my-space my-section-style">
          <h2 className="font-bungee text-4xl text-center">
            Little more curious about our services?
          </h2>
          <p className="py-5 text-center">
            We'll be happy to help you. Don't be shy, just ping us.
          </p>

          <Link to="/contact">
            <button className="my-btn bg-[#5433FF] text-white uppercase font-bold">
              Contact Us
            </button>
          </Link>
        </div>
      </section>
    </main>
  );
};

export default Services;
