import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";

import { CgMenu, CgMenuMotion } from "react-icons/cg";

import logo from "../props/logo/kats-infotech-logo.png";

const Navbar = () => {
  const btnRef = useRef(null);
  const [menuClicked, setMenuClicked] = useState(true);

  useEffect(() => {
    const closeDropdown = (e) => {
      if (e.path[1] !== btnRef.current) {
        setMenuClicked((menuClicked) => true);
      }
    };
    document.body.addEventListener("click", closeDropdown);

    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  return (
    <nav className="my-theme-color bg-opacity-50 text-white h-16 sticky top-0">
      <div className="my-space space-x-2 flex flex-wrap justify-between items-center font-bold">
        <div>
          <NavLink to="/">
            <img src={logo} alt="Kats Infotech logo" className="h-12" />
          </NavLink>
        </div>

        <div className="hidden md:flex justify-between mx-10 space-x-5">
        <NavLink to="/" className={({ isActive }) => (isActive ? "p-3 rounded-md my-active-link" : "p-3 rounded-md my-nav-link-hover")}>
            Home
          </NavLink>
          <NavLink to="/company" className={({ isActive }) => (isActive ? "p-3 rounded-md my-active-link" : "p-3 rounded-md my-nav-link-hover")}>
            Company
          </NavLink>
          <NavLink to="/services" className={({ isActive }) => (isActive ? "p-3 rounded-md my-active-link" : "p-3 rounded-md my-nav-link-hover")}>
            Services
          </NavLink>
          <NavLink
            to="/industries"
            className={({ isActive }) => (isActive ? "p-3 rounded-md my-active-link" : "p-3 rounded-md my-nav-link-hover")}
          >
            Industries
          </NavLink>
          <NavLink to="/contact" className={({ isActive }) => (isActive ? "p-3 rounded-md my-active-link" : "p-3 rounded-md my-nav-link-hover")}>
            Contact
          </NavLink>
        </div>

        <button
          ref={btnRef}
          onClick={() => setMenuClicked(!menuClicked)}
          className="bg-[#20BDFF] my-btn rounded p-1 md:hidden"
        >
          {menuClicked ? <CgMenu size={24} /> : <CgMenuMotion size={24} />}
        </button>
      </div>

      {!menuClicked ? (
        <div className="bg-black bg-opacity-80 absolute right-0 w-1/2 -mt-2 rounded-b-md text-center">
          <div className="container p-3 flex flex-col">
            <NavLink to="/" className={({ isActive }) => (isActive ? "p-3 rounded-md my-active-link" : "p-3 rounded-md my-nav-link-hover")}>
              Home
            </NavLink>
            <NavLink to="/company" className={({ isActive }) => (isActive ? "p-3 rounded-md my-active-link" : "p-3 rounded-md my-nav-link-hover")}>
              Company
            </NavLink>
            <NavLink
              to="/services"
              className={({ isActive }) => (isActive ? "p-3 rounded-md my-active-link" : "p-3 rounded-md my-nav-link-hover")}
            >
              Services
            </NavLink>
            <NavLink
              to="/industries"
              className={({ isActive }) => (isActive ? "p-3 rounded-md my-active-link" : "p-3 rounded-md my-nav-link-hover")}
            >
              Industries
            </NavLink>
            <NavLink to="/contact" className={({ isActive }) => (isActive ? "p-3 rounded-md my-active-link" : "p-3 rounded-md my-nav-link-hover")}>
              Contact
            </NavLink>
          </div>
        </div>
      ) : (
        <></>
      )}
    </nav>
  );
};
export default Navbar;
